// ** Dropdowns Imports
import IntlDropdown from './IntlDropdown.js'

const NavbarIntel = () => {
  return (
    <ul className='nav navbar-nav align-items-center me-auto'>
      <IntlDropdown />
    </ul>
  )
}
export default NavbarIntel
