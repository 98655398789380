// ** React Imports
import { createContext, useEffect, useState } from 'react';

// ** Create Context
const ThemeColors = createContext();

const ThemeContext = ({ children }) => {
   // ** State
   const [colors, setColors] = useState({});

   //** ComponentDidMount
   useEffect(() => {
      if (window !== 'undefined') {
         //** Get variable value
         const getHex = (color) =>
            window.getComputedStyle(document.body).getPropertyValue(color).trim();

         //** Colors obj
         const obj = {
            primary: {
               light: getHex('--bs-primary').concat('1a'),
               main: getHex('--bs-primary'),
            },
            secondary: {
               light: getHex('--bs-secondary').concat('1a'),
               main: getHex('--bs-secondary'),
            },
            success: {
               light: getHex('--bs-success').concat('1a'),
               main: getHex('--bs-success'),
            },
            danger: {
               light: getHex('--bs-danger').concat('1a'),
               main: getHex('--bs-danger'),
            },
            warning: {
               light: getHex('--bs-warning').concat('1a'),
               main: getHex('--bs-warning'),
            },
            info: {
               light: getHex('--bs-info').concat('1a'),
               main: getHex('--bs-info'),
            },
            dark: {
               light: getHex('--bs-dark').concat('1a'),
               main: getHex('--bs-dark'),
            },
            white: {
               main: getHex('--bs-white'),
            },
            black: {
               main: getHex('--bs-black'),
            },
            blue: {
               light: getHex('--bs-blue').concat('1a'),
               main: getHex('--bs-blue'),
            },
            charts: {
               primary: getHex('--bs-secondary'),
               secondary: getHex('--bs-secondary'),
               gradientPrimary: [getHex('--bs-blue'), getHex('--bs-secondary')],
               success: getHex('--bs-success'),
               danger: getHex('--bs-danger'),
               warning: getHex('--bs-warning'),
               info: getHex('--bs-info'),
               blue: getHex('--bs-blue'),
               theme1: [
                  getHex('--bs-blue'),
                  getHex('--bs-secondary'),
                  getHex('--bs-warning'),
                  getHex('--bs-success'),
                  getHex('--bs-info'),
               ],
               theme2: [
                  getHex('--bs-secondary'),
                  getHex('--bs-warning'),
                  getHex('--bs-brown'),
                  getHex('--bs-maroon'),
                  getHex('--bs-smokyBlack'),
                  getHex('--bs-terraCotta'),
               ],
               theme3: [
                  getHex('--bs-danger'),
                  getHex('--bs-secondary'),
                  getHex('--bs-warning'),
                  getHex('--bs-danger'),
                  getHex('--bs-success'),
               ],
               map: ['#5063af', '#dd1e35', '#ffc63e', '#43b77a', '#27afbf'],
            },
         };

         setColors({ ...obj });
      }
   }, []);

   return <ThemeColors.Provider value={{ colors }}>{children}</ThemeColors.Provider>;
};

export { ThemeColors, ThemeContext };
