// ** Logo
import logo from '@src/assets/images/logo/logo.svg';
import RingLoader from 'react-spinners/RingLoader';

const SpinnerComponent = () => {
   return (
      <div className="fallback-spinner app-loader">
         <img className="fallback-logo" src={logo} alt="logo" width={150} />
         <div className="loading">
            <div>
               {/* <ComponentSpinner /> */}
               <RingLoader color="#000" />
            </div>
         </div>
      </div>
   );
};

export default SpinnerComponent;
