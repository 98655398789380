import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUnixTime } from 'date-fns';
import { getData } from '../../Api/api';
import { EP_LOCATION_SALES, EP_LOCATION_STATS } from '../../Api/endPoints';
import { continentsChart } from './localizations.mapper';

export const getLocationStats = createAsyncThunk(
   'location/getLocationStats',
   async ({ startDate, endDate, brand = 0 }, thunkAPI) => {
      try {
         const params = {
            start: getUnixTime(startDate),
            end: getUnixTime(endDate),
            brand,
            timezone: new Date().getTimezoneOffset(),
         };

         const {
            sales_per_country: salesPerCountry,
            sales_per_continent: salesPerContinent,
            continent_sales_per_period: continentSalesPerPeriod,
         } = await getData(EP_LOCATION_STATS, { params });

         const { localizationsStatsData } = thunkAPI.getState().localizations;

         return {
            ...localizationsStatsData,
            salesPerCountry: salesPerCountry ?? [],
            salesPerContinent: salesPerContinent ?? {},
            salesPerCountryPerContinent: salesPerCountry?.sort((a, b) => b.total - a.total) || [],
            charts: { continents: continentsChart(continentSalesPerPeriod, startDate) },
         };
      } catch (error) {
         return thunkAPI.rejectWithValue(error?.message);
      }
   },
);

export const getLocationSales = createAsyncThunk(
   'location/getLocationSales',
   async ({ startDate, endDate, brand = 0, country = 'FR' }, thunkAPI) => {
      try {
         const params = {
            start: getUnixTime(startDate),
            end: getUnixTime(endDate),
            brand,
            country,
            count: 20,
            timezone: new Date().getTimezoneOffset(),
         };

         const {
            influencers_sales_per_country,
            sm_sales_per_country: smSalesPerCountry,
         } = await getData(EP_LOCATION_SALES, { params });

         const { localizationsStatsData } = thunkAPI.getState().localizations;

         // FIXME : data should be treated in backend
         const groupedArray =
            smSalesPerCountry?.reduce((result, item) => {
               const socialMedia = item.socialMedia ?? 'other';
               if (!result[socialMedia]) {
                  result[socialMedia] = { socialMedia, sales_nbr: 0, total: 0, clics: 0 };
               }
               result[socialMedia].sales_nbr += item.sales_nbr;
               result[socialMedia].total += item.total;
               result[socialMedia].clics += item.clics;
               return result;
            }, {}) ?? [];

         return {
            ...localizationsStatsData,
            influencersSalesPerCountry:
               influencers_sales_per_country?.filter((value) => Object.keys(value).length !== 0) ??
               [],
            smSalesPerCountry: Object.values(groupedArray),
         };
      } catch (error) {
         return thunkAPI.rejectWithValue(error?.message);
      }
   },
);
