import { createSlice } from '@reduxjs/toolkit';
import { preprocessData } from './localizations.mapper';
import { getLocationSales, getLocationStats } from './localizations.thunk';

const initialState = {
   localizationsStatsData: {
      salesPerCountry: [],
      salesPerContinent: {},
      salesPerCountryPerContinent: [],
      influencersSalesPerCountry: [],
      smSalesPerCountry: [],
      charts: {
         continents: {
            unit: '',
            labels: [],
            datasets: [],
         },
      },
   },
   getLocationStatsStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   getLocationSalesStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   errors: {},
};

export const localizationsSlice = createSlice({
   name: 'localizations',
   initialState,
   reducers: {
      getSalesPerCountryPerContinent: (state, action) => {
         let id = action.payload;
         state.localizationsStatsData = {
            ...state.localizationsStatsData,
            salesPerCountryPerContinent: preprocessData(
               state.localizationsStatsData.salesPerCountry.filter((item) => item.continent === id),
            ),
         };
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getLocationStats.pending, (state, action) => {
            state.getLocationStatsStatus = 'loading';
         })
         .addCase(getLocationStats.fulfilled, (state, action) => {
            delete state.errors.locationStats; //find error in Errors and delete it
            state.localizationsStatsData = { ...state.localizationsStatsData, ...action.payload };
            state.getLocationStatsStatus = 'succeeded';
         })
         .addCase(getLocationStats.rejected, (state, action) => {
            state.errors = { ...state.errors, locationStats: action.payload };
            state.getLocationStatsStatus = 'failed';
            state.getLocationSalesStatus = 'failed';
         })

         .addCase(getLocationSales.pending, (state, action) => {
            state.getLocationSalesStatus = 'loading';
         })
         .addCase(getLocationSales.fulfilled, (state, action) => {
            delete state.errors.locationSales; //find error in Errors and delete it
            state.localizationsStatsData = { ...state.localizationsStatsData, ...action.payload };
            state.getLocationSalesStatus = 'succeeded';
         })
         .addCase(getLocationSales.rejected, (state, action) => {
            state.errors = { ...state.errors, locationSales: action.payload };
            state.getLocationSalesStatus = 'failed';
         });
   },
});

export const selectLocalizationsStatsData = (state) => state.localizations.localizationsStatsData;

export const selectSalesPerCountryPerContinent = (state) =>
   state.localizations.localizationsStatsData.salesPerCountryPerContinent;

export const selectLocationStatsStatus = (state) => state.localizations.getLocationStatsStatus;

export const selectLocationSalesStatus = (state) => state.localizations.getLocationSalesStatus;

export const selectLocalizationsErrorsStats = (state) => state.localizations.errors?.locationStats;

export const selectLocalizationsErrorsSales = (state) => state.localizations.errors?.locationSales;

export const { getSalesPerCountryPerContinent } = localizationsSlice.actions;

export default localizationsSlice.reducer;
