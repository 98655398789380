import { createSlice } from '@reduxjs/toolkit';
import { getPeriodicityPerformances, getPeriodicityStats } from './periodicity.thunk.js';

const initialState = {
   periodStatsData: {
      salesGrowth: {
         transactions: {
            unit: 'month',
            labels: [],
            datasets: [
               { label: 'Transactions', data: [] },
               {
                  label: 'Prev Transaction',
                  prevData: [],
               },
            ],
         },
         sales: {
            value: 0,
            label: '',
         },
      },
      salesGranularity: {
         salesByDate: {
            unit: 'day',
            labels: [],
            datasets: [
               {
                  data: [],
                  label: '',
               },
            ],
         },
         salesByWeekDay: {
            labels: [],
            datasets: [
               {
                  data: [],
                  label: '',
               },
            ],
         },
         salesByPartDay: {
            labels: [],
            datasets: [
               {
                  data: [],
               },
            ],
         },
         salesByHoursDay: {
            unit: 'hour',
            labels: [],
            datasets: [{ label: '', data: [] }],
         },
      },
   },
   getPeriodicityStatsStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   getPeriodicityPerformancesStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   errors: {},
};

export const periodicitySlice = createSlice({
   name: 'periodicity',
   initialState,
   extraReducers(builder) {
      builder
         .addCase(getPeriodicityStats.pending, (state, action) => {
            state.getPeriodicityStatsStatus = 'loading';
            state.periodStatsData = initialState.periodStatsData;
         })
         .addCase(getPeriodicityStats.fulfilled, (state, action) => {
            delete state.errors.periodStats; //find error in Errors and delete it
            state.periodStatsData = { ...state.periodStatsData, ...action.payload };
            state.getPeriodicityStatsStatus = 'succeeded';
         })
         .addCase(getPeriodicityStats.rejected, (state, action) => {
            state.errors = { ...state.errors, periodStats: action.payload };
            state.getPeriodicityStatsStatus = 'failed';
         })

         .addCase(getPeriodicityPerformances.pending, (state, action) => {
            state.getPeriodicityPerformancesStatus = 'loading';
         })
         .addCase(getPeriodicityPerformances.fulfilled, (state, action) => {
            delete state.errors.periodPerforms; //find error in Errors and delete it
            state.periodStatsData = { ...state.periodStatsData, ...action.payload };
            state.getPeriodicityPerformancesStatus = 'succeeded';
         })
         .addCase(getPeriodicityPerformances.rejected, (state, action) => {
            state.errors = { ...state.errors, periodPerforms: action.payload };
            state.getPeriodicityPerformancesStatus = 'failed';
         });
   },
});

export const selectPeriodStatsData = (state) => state.periodicity.periodStatsData;

export const selectPeriodicityStatsStatus = (state) => state.periodicity.getPeriodicityStatsStatus;

export const selectPeriodicityPerformancesStatus = (state) =>
   state.periodicity.getPeriodicityPerformancesStatus;

export const selectPeriodicityErrorsStats = (state) => state.periodicity.errors?.periodStats;

export const selectPeriodicityErrorsPerforms = (state) => state.periodicity.errors?.periodPerforms;

export default periodicitySlice.reducer;
