import { createAsyncThunk } from '@reduxjs/toolkit'
import { auth, database } from '../../@core/auth/firebase/firebase.js'
import { postData } from '../../Api/api'
import { EP_SEND_CAPTCHA, EP_SEND_CONTACT_US_EMAIL } from '../../Api/endPoints'
import { clearDatePicker } from '../datePicker/datePicker.slice.js'

export const login = createAsyncThunk('user/login', async (values, thunkAPI) => {
  const connectionMethod = values.checked ? 'local' : 'session'

  let signInPromise = {}

  const token = values?.token || null
  const persistTokenType = values?.persistTokenType

  try {
    if (token) {
      const customToken = token.slice(1)
      // [START auth_sign_in_custom]
      let persistancePromise = await auth.setPersistence(persistTokenType)
      signInPromise = await auth
        .signInWithCustomToken(customToken)
        .then((userCredential) => {
          // Signed in
          return userCredential.user
        })
        .catch((error) => {
          var errorCode = error.code
          var errorMessage = error.message
          // throw new Error(error.code);
          return thunkAPI.rejectWithValue('MSG_ERROR_WRONG')
        })
      // [END auth_sign_in_custom]
    } else {
      try {
        await auth.setPersistence(connectionMethod)
        signInPromise = await auth
          .signInWithEmailAndPassword(values.email, values.password)
          .then((userCredential) => {
            // Signed in

            return userCredential.user
            // ...
          })
      } catch (err) {
        throw new Error(err.code)
      }
    }

    const claims = await signInPromise?.getIdTokenResult().then((idTokenResult) => {
      return idTokenResult.claims
    })

    const role = claims ? (claims?.admin ? 'admin' : claims?.brand ? 'brand' : '') : null

    if (!role && !['admin', 'brand'].includes(role)) {
      throw new Error('MSG_ERROR_WRONG')
    }

    return { ...signInPromise.multiFactor.user, ...claims, role }
  } catch (error) {
    auth.signOut()
    return thunkAPI.rejectWithValue(error)
  }
})

export const logout = createAsyncThunk('user/logout', async (_, thunkAPI) => {
  try {
    auth.signOut().then(() => {
      thunkAPI.dispatch(clearDatePicker())
    })
    return
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})
export const getFbBrand = createAsyncThunk('user/brands', async (_offerId, thunkAPI) => {
  try {
    let brand = await database
      .ref('brands/-MxZWFBi0Qe4XVroNgc6')
      .once('value')
      .then((snap) => {
        return snap.exists() ? snap.val() : null
      })
      .catch((err) => null)
    return brand
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})
export const setCurrentUser = createAsyncThunk('user/setCurrentUser', async (data, thunkAPI) => {
  try {
    const [idTokenResult, brand] = await Promise.all([
      data.userData.getIdTokenResult(),
      thunkAPI.dispatch(getFbBrand(2620)),
    ])

    const role = 'brand'
    const claims = idTokenResult.claims

    return { ...data, role, brand }
  } catch (error) {
    auth.signOut()
    return thunkAPI.rejectWithValue(error)
  }
})

export const sendContactUsEmail = createAsyncThunk(
  'user/sendContactUsEmail',
  async ({ message, email, name, company, phoneNumber, dep }, thunkAPI) => {
    try {
      const params = { message, email, name, company, phoneNumber: phoneNumber?.trim(), dep }

      return await postData(EP_SEND_CONTACT_US_EMAIL, { ...params })
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const UpdateCaptcha = createAsyncThunk(
  'user/UpdateCaptcha',
  async ({ displayCaptcha, uid }, thunkAPI) => {
    try {
      const params = { displayCaptcha, uid }
      try {
        await postData(EP_SEND_CAPTCHA, { ...params })
      } catch (error) {}
      return false
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const setCaptcha = createAsyncThunk(
  'user/UpdateCaptcha',
  async (displayCaptcha, thunkAPI) => {
    try {
      return displayCaptcha
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)
