import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
import translationEN from '@locales/en/translation.json'
import translationFR from '@locales/fr/translation.json'

import dashboardEN from '@locales/en/dashboard.json'
import dashboardFR from '@locales/fr/dashboard.json'

import periodicityEN from '@locales/en/periodicity.json'
import periodicityFR from '@locales/fr/periodicity.json'

import conversionEN from '@locales/en/conversion.json'
import conversionFR from '@locales/fr/conversion.json'

import deviceEN from '@locales/en/device.json'
import deviceFR from '@locales/fr/device.json'

import countriesEN from '@locales/en/countries.json'
import countriesFR from '@locales/fr/countries.json'

import influencersEN from '@locales/en/influencer.json'
import influencersFR from '@locales/fr/influencer.json'

import productsEN from '@locales/en/product.json'
import productsFR from '@locales/fr/product.json'

import campaignsEN from '@locales/en/campaigns.json'
import campaignsFR from '@locales/fr/campaigns.json'

import localizationsEN from '@locales/en/localizations.json'
import localizationsFR from '@locales/fr/localizations.json'

import loginEN from '@locales/en/login.json'
import loginFR from '@locales/fr/login.json'

import notfoundEN from '@locales/en/notfound.json'
import notfoundFR from '@locales/fr/notfound.json'

const languages = ['en', 'fr']

const resources = {
  en: {
    translation: translationEN,
    dashboard: dashboardEN,
    periodicity: periodicityEN,
    conversion: conversionEN,
    device: deviceEN,
    countries: countriesEN,
    influencers: influencersEN,
    products: productsEN,
    campaigns: campaignsEN,
    localizations: localizationsEN,
    login: loginEN,
    notfound: notfoundEN,
  },
  fr: {
    translation: translationFR,
    dashboard: dashboardFR,
    periodicity: periodicityFR,
    conversion: conversionFR,
    device: deviceFR,
    countries: countriesFR,
    influencers: influencersFR,
    products: productsFR,
    campaigns: campaignsFR,
    localizations: localizationsFR,
    login: loginFR,
    notfound: notfoundFR,
  },
}

const navigatorLang = window.navigator.language.split('-')?.[0]

const dev = Boolean(process.env.NODE_ENV === 'development')

i18next.use(initReactI18next).init({
  resources,
  whitelist: languages,
  lng: navigatorLang,
  interpolation: {
    escapeValue: false,
  },
  debug: false,
})

export default i18next
