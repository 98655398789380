// ** Third Party Components

import { useEffect } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

const IntlDropdown = () => {
  // ** Hooks
  const { i18n } = useTranslation()

  // ** Vars
  const langObj = {
    en: 'English',
    fr: 'Français',
    // de: "German",
    // pt: "Portuguese"
  }

  useEffect(() => {
    const getLanguage = localStorage.getItem('lang')
    if (getLanguage) {
      i18n.changeLanguage(getLanguage)
    }
    return () => {
      localStorage.removeItem('lang')
    }
  }, [])

  // ** Function to switch Language
  const handleLangUpdate = (e, language) => {
    e.preventDefault()
    i18n.changeLanguage(language)
    localStorage.setItem('lang', language)
  }

  return (
    <UncontrolledDropdown
      href='/'
      tag='li'
      className='dropdown-language nav-item list-unstyled d-flex align-items-center'
    >
      <DropdownToggle href='/' tag='a' className='nav-link' onClick={(e) => e.preventDefault()}>
        <ReactCountryFlag
          svg
          className='country-flag flag-icon'
          countryCode={i18n.language === 'en' ? 'us' : i18n.language}
        />
        <span className='selected-language'>{langObj[i18n.language]}</span>
      </DropdownToggle>
      <DropdownMenu className='top-0' end>
        <DropdownItem href='/' tag='a' onClick={(e) => handleLangUpdate(e, 'en')}>
          <ReactCountryFlag className='country-flag' countryCode='us' svg />
          <span className='ms-1'>{langObj.en}</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' onClick={(e) => handleLangUpdate(e, 'fr')}>
          <ReactCountryFlag className='country-flag' countryCode='fr' svg />
          <span className='ms-1'>{langObj.fr}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default IntlDropdown
