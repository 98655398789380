import { createSlice } from '@reduxjs/toolkit'
import {
  UpdateCaptcha,
  getFbBrand,
  login,
  logout,
  sendContactUsEmail,
  setCurrentUser,
} from './user.thunk.js'

const initialState = {
  // ** Stats ** STATUS
  user: null,
  isLoadingUser: false,
  isLoadingBrand: false,
  loadingBrandStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
  contactEmailStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
  loadingCurrentUser: 'idle', //'idle' | 'loading'
  userStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
  displayCaptcha: null,
  errors: {},
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoadingUser = true
        state.userStatus = 'loading'
      })
      .addCase(login.fulfilled, (state, action) => {
        delete state.errors.user //find error in Errors and delete it
        state.user = action.payload
        state.isLoadingUser = false
        state.userStatus = 'succeeded'
      })
      .addCase(login.rejected, (state, action) => {
        const err = action.payload
        let data = {}
        switch (err.message) {
          case 'auth/wrong-password':
            data = { attribut: 'password', message: err.message }
            break
          case 'auth/user-not-found':
            data = { attribut: 'email', message: err.message }
            break
          case 'auth/too-many-requests':
            data = { attribut: 'email', message: err.message }
            break
          default:
            data = { attribut: '', message: err.message }
            break
        }
        state.errors = { ...state.errors, user: data }
        state.isLoadingUser = false
        state.userStatus = 'failed'
      })

      .addCase(getFbBrand.pending, (state, action) => {
        state.isLoadingBrand = true
        state.loadingBrandStatus = 'loading'
      })
      .addCase(getFbBrand.fulfilled, (state, action) => {
        delete state.errors.user //find error in Errors and delete it
        // state.user = action.payload
        state.isLoadingBrand = false
        state.loadingBrandStatus = 'succeeded'
      })
      .addCase(getFbBrand.rejected, (state, action) => {
        state.errors = { ...state.errors, user: action.error.message }
        state.isLoadingBrand = false
        state.loadingBrandStatus = 'failed'
      })

      .addCase(logout.pending, (state, action) => {
        state.isLoadingUser = true
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.user = null
        delete state.errors.userLogout
        state.isLoadingUser = false
      })
      .addCase(logout.rejected, (state, action) => {
        state.errors = { ...state.errors, userLogout: action.error.message }
        state.isLoadingUser = false
      })

      .addCase(setCurrentUser.pending, (state, action) => {
        state.loadingCurrentUser = true
      })
      .addCase(setCurrentUser.fulfilled, (state, action) => {
        state.user = { ...action.payload.userData, ...action.payload.additionalData }
        state.user?.role === 'admin'
          ? (state.user = { ...state.user, brand: { offerId: 2620 } })
          : ''
        delete state.errors.currentUser
        state.loadingCurrentUser = false
      })
      .addCase(setCurrentUser.rejected, (state, action) => {
        state.errors = { ...state.errors, currentUser: action.error.message }
        state.loadingCurrentUser = false
      })

      .addCase(sendContactUsEmail.pending, (state, action) => {
        state.contactEmailStatus = 'loading'
      })
      .addCase(sendContactUsEmail.fulfilled, (state, action) => {
        state.contactEmailStatus = 'succeeded'
      })
      .addCase(sendContactUsEmail.rejected, (state, action) => {
        state.contactEmailStatus = 'failed'
      })
      .addCase(UpdateCaptcha.fulfilled, (state, action) => {
        state.displayCaptcha = action.payload
      })
  },
})

export const selectUser = (state) => state.user
export const selectUserErrors = (state) => state.user.errors

export const selectIsLoadingBrand = (state) => state.user.loadingBrandStatus

export const selectContactEmailStatus = (state) => state.user.contactEmailStatus

export const selectUserStatus = (state) => state.user.userStatus

export const selectBrand = (state) => state.user?.user?.brand

export const selectDisplayCaptcha = (state) => state.user?.displayCaptcha

export default userSlice.reducer
