import { createSlice } from '@reduxjs/toolkit';
import {
   getInfluencerPerformances,
   getInfluencers,
   getSaleByInfluencerId,
} from './influencer.thunk.js';

const initialState = {
   influencerStatsData: {
      global: {
         averageCart: 0,
         averageClicks: 0,
         bestSocialMedia: {
            total_clicks: 0,
            socialMedia: '',
         },
         activeInfluencersNbr: 0,
      },
      influencers: [],
      charts: {
         sales: {
            unit: '',
            labels: [],
            datasets: [],
         },
         bestOfInfluencers: {
            labels: [],
            datasets: {
               data: [],
            },
         },
      },
   },
   getInfluencerPerformancesStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   getInfluencersStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   getSaleByInfluencerIdStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   errors: {},
};

export const influencerSlice = createSlice({
   name: 'influencer',
   initialState,
   reducers: {
      initialiseSaleByInfluencerId: (state, action) => {
         state.influencerStatsData = {
            ...state.influencerStatsData,
            charts: {
               ...state.influencerStatsData.charts,
               sales: {
                  unit: '',
                  labels: [],
                  datasets: [],
               },
            },
         };
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getInfluencerPerformances.pending, (state, action) => {
            state.getInfluencerPerformancesStatus = 'loading';
            state.influencerStatsData = initialState.influencerStatsData;
         })
         .addCase(getInfluencerPerformances.fulfilled, (state, action) => {
            delete state.errors.influencerStats; //find error in Errors and delete it
            state.influencerStatsData = { ...state.influencerStatsData, ...action.payload };
            state.getInfluencerPerformancesStatus = 'succeeded';
         })
         .addCase(getInfluencerPerformances.rejected, (state, action) => {
            state.errors = { ...state.errors, influencerStats: action.payload };
            state.getInfluencerPerformancesStatus = 'failed';
         })

         .addCase(getInfluencers.pending, (state, action) => {
            state.getInfluencersStatus = 'loading';
         })
         .addCase(getInfluencers.fulfilled, (state, action) => {
            delete state.errors.influencers; //find error in Errors and delete it
            state.influencerStatsData = { ...state.influencerStatsData, ...action.payload };
            state.getInfluencersStatus = 'succeeded';
         })
         .addCase(getInfluencers.rejected, (state, action) => {
            state.errors = { ...state.errors, influencers: action.payload };
            state.getInfluencersStatus = 'failed';
         })

         .addCase(getSaleByInfluencerId.pending, (state, action) => {
            state.getDashboardStatsStatus = 'loading';
         })
         .addCase(getSaleByInfluencerId.fulfilled, (state, action) => {
            delete state.errors.saleByInfluencerId; //find error in Errors and delete it
            state.influencerStatsData = { ...state.influencerStatsData, ...action.payload };
            state.getDashboardStatsStatus = 'succeeded';
         })
         .addCase(getSaleByInfluencerId.rejected, (state, action) => {
            state.errors = { ...state.errors, saleByInfluencerId: action.payload };
            state.getDashboardStatsStatus = 'failed';
         });
   },
});

export const { initialiseSaleByInfluencerId } = influencerSlice.actions;

export const selectInfluencerStatsData = (state) => state.influencer.influencerStatsData;

export const selectInfluencerPerformancesStatus = (state) =>
   state.influencer.getInfluencerPerformancesStatus;

export const selectInfluencersStatus = (state) => state.influencer.getInfluencersStatus;

export const selectDashboardStatsStatus = (state) => state.influencer.getDashboardStatsStatus;

export const selectInfluencerErrorsStats = (state) => state.influencer.errors?.influencerStats;

export const selectInfluencerErrorsInfluencers = (state) => state.influencer.errors?.influencers;

export const selectInfluencerErrorsSaleByInfluencerId = (state) =>
   state.influencer.errors?.saleByInfluencerId;

export default influencerSlice.reducer;
