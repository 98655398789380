import _ from 'lodash';
import { toPercent } from '../../utility/Utils';

export const subCategTableData = (values, language, t) => {
   return _.orderBy(
      values?.map((item) => {
         let name = item.supplier;
         if (['Other', 'NOT_IDENTIFIED', 'OTHER'].includes(name)) {
            name = t('COMMON.NOT_IDENTIFIED');
         }
         return {
            name,
            transactions: item.sales_nbr,
            clicks: item.clics,
            rate: toPercent(item.conversions_rate ? item.conversions_rate * 100 : 0),
         };
      }),
      'transactions',
      'desc',
   );
};

export const influencerTableData = (values, t) => {
   return values
      ? _.orderBy(
           values.map((item) => {
              return {
                 name: ['unknown'].includes(item.influencer)
                    ? t('translation:UNKNOWN')
                    : item.influencer,
                 transactions: item.sales_nbr,
                 clicks: item.clics,
                 rate: toPercent(item.conversions_rate ? item.conversions_rate * 100 : 0),
              };
           }),
           'sales_nbr',
           'desc',
        )
      : [];
};

export const socialMediaTableData = (values, t) => {
   return _.orderBy(
      values
         ? values
              .filter((s) => s.socialMedia)
              .map((item) => {
                 let name = item.socialMedia;
                 if (['Other', 'other'].includes(name)) {
                    name = t('TEXT.OTHER');
                 }
                 return {
                    name,
                    transactions: item.sales_nbr,
                    clicks: item.clics,
                    rate: toPercent(item.conversions_rate ? item.conversions_rate * 100 : 0),
                 };
              })
         : [],
      'transactions',
      'desc',
   );
};
