import { createSlice } from '@reduxjs/toolkit';
import { getConversionsPerform } from './conversion.thunk.js';

const initialState = {
   conversionsStatsData: {
      conversionsRate: 0,
      subCateg: {
         chartData: {
            labels: [],
            datasets: { data: [] },
         },
         tableData: [],
      },
      influencer: {
         chartData: {
            labels: [],
            datasets: { data: [] },
         },
         tableData: [],
      },
      socialMedia: {
         chartData: {
            labels: [],
            datasets: { data: [] },
         },
         tableData: [],
      },
   },
   getConversionsPerformStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   errors: {},
};

export const conversionSlice = createSlice({
   name: 'conversion',
   initialState,
   extraReducers(builder) {
      builder
         .addCase(getConversionsPerform.pending, (state, action) => {
            state.getConversionsPerformStatus = 'loading';
            state.conversionsStatsData = initialState.conversionsStatsData;
         })
         .addCase(getConversionsPerform.fulfilled, (state, action) => {
            delete state.errors.stats; //find error in Errors and delete it
            state.conversionsStatsData = { ...state.conversionsStatsData, ...action.payload };
            state.getConversionsPerformStatus = 'succeeded';
         })
         .addCase(getConversionsPerform.rejected, (state, action) => {
            state.errors = { ...state.errors, stats: action.payload };
            state.getConversionsPerformStatus = 'failed';
         });
   },
});

export const selectConversionsStatsData = (state) => state.conversion.conversionsStatsData;

export const selectConversionsPerformStatus = (state) =>
   state.conversion.getConversionsPerformStatus;

export const selectConversionErrorsStats = (state) => state.conversion.errors?.stats;

export default conversionSlice.reducer;
