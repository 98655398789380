import { createSlice } from '@reduxjs/toolkit'
import { getCampaignsStats } from './campaigns.thunk'

const initialState = {
  // ** Stats ** STATUS
  campaignsStatsData: {
    kpis: {
      sales: 0,
      transactions: 0,
      clicks: 0,
      sharingNumber: 0,
    },
    campaigns: [
      {
        name: 'Idee2ook',
        sale: '3 675.73 €',
        turnover: '0.52 €',
        clicks: 7,
        conversion: '2 %',
        averageCart: '30 €',
        socialNetwork: 'Instagram',
      },
      {
        name: 'Laurelay',
        sale: '3 675.73 €',
        turnover: '0.52 €',
        clicks: 7,
        conversion: '2 %',
        averageCart: '30 €',
        socialNetwork: 'Instagram',
      },
      {
        name: 'Di it In Paris',
        sale: '3 675.73 €',
        turnover: '0.52 €',
        clicks: 7,
        conversion: '2 %',
        averageCart: '30 €',
        socialNetwork: 'Instagram',
      },
      {
        name: 'Mina',
        sale: '3 675.73 €',
        turnover: '0.52 €',
        clicks: 7,
        conversion: '2 %',
        averageCart: '30 €',
        socialNetwork: 'Instagram',
      },
      {
        name: 'Lilylovesfashio',
        sale: '3 675.73 €',
        turnover: '0.52 €',
        clicks: 7,
        conversion: '2 %',
        averageCart: '30 €',
        socialNetwork: 'Instagram',
      },
      {
        name: 'Lilylovesfashio',
        sale: '3 675.73 €',
        turnover: '0.52 €',
        clicks: 7,
        conversion: '2 %',
        averageCart: '30 €',
        socialNetwork: 'Instagram',
      },
    ],
    charts: {
      conversionByInfluencer: {
        labels: ['Noir', 'Blanc', 'Rouge', 'Bleu', 'Vert', 'Gris'],
        datasets: {
          data: [0.5, 0.12, 0.16, 0.06, 0.09, 0.01],
        },
      },
      conversionBySocialMedia: {
        labels: ['Instagram', 'Facebook'],
        datasets: {
          data: [0.5, 0.12],
        },
      },
      countries: {
        labels: [
          {
            countryCode: 'FR',
            value: '1284.03K €',
          },
          {
            countryCode: 'BE',
            value: '9.30K €',
          },
          {
            countryCode: 'CH',
            value: '4.80K €',
          },
          {
            countryCode: 'TN',
            value: '2.47K €',
          },
          {
            countryCode: 'GB',
            value: '2.30K €',
          },
        ],
        datasets: {
          data: [10, 9, 8, 7, 6],
        },
      },
      conversion: {
        value: 90,
        label: 'Conversion',
      },
      sales: {
        unit: 'month',
        labels: [],
        datasets: {
          data: [],
          label: '',
        },
      },
    },
    global: {
      ca: 'lorem ipsom',
      transaction: 'lorem ipsom',
    },
  },
  isLoadingCampaignsStats: false,
  errors: {},
}

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {},
  extraReducers: {
    // ** Stats ** fetch Data
    [getCampaignsStats.pending]: (state) => {
      state.isLoadingInfluencerStats = true
    },
    [getCampaignsStats.fulfilled]: (state, action) => {},
    [getCampaignsStats.rejected]: (state, action) => {
      state.isLoadingInfluencerStats = false
    },
  },
})

export const selectCampaigns = (state) => state.campaigns

export default campaignsSlice.reducer
