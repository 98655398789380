// ** React Imports
import { Suspense, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { RingLoader } from 'react-spinners'

// ** Context Imports

import { useAuth } from '../../auth/firebase/useAuth.js'
import { getUserToken } from '../../../utility/Utils.js'

const PrivateRoute = ({ children, route }) => {
  const { user, loadingCurrentUser, currentUser } = useAuth()

  const navigate = useNavigate()

  const handleCurrentUser = async () => {
    const userToken = await getUserToken()
    if (userToken) {
      currentUser()
    } else {
      navigate('/login')
    }
  }
  // ** Hooks & Vars
  // const ability = useContext(AbilityContext)
  useEffect(() => {
    handleCurrentUser()
  }, [])

  if (route) {
    let action = null
    let resource = null
    let restrictedRoute = false

    if (route.meta) {
      action = route.meta.action
      resource = route.meta.resource
      restrictedRoute = route.meta.restricted
    }
  }

  const fallback = () => (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexGrow: '1',
      }}
    >
      <RingLoader color='#022957' />
    </div>
  )

  if (user && !loadingCurrentUser) {
    return <Suspense fallback={null}>{children}</Suspense>
  }
}
export default PrivateRoute
