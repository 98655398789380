import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUnixTime } from 'date-fns';
import { getData } from '../../Api/api';
import { EP_PERIODICITY_PERFORMANCES, EP_PERIODICITY_STATS } from '../../Api/endPoints';
import { calcPercentageGrowth } from '../../utility/Utils.js';
import { generateDataChart, generateMonthlyChartData } from './periodicity.mapper';

export const getPeriodicityStats = createAsyncThunk(
   'periodicity/getPeriodicityStats',
   async ({ startDate, endDate, brand = 0, t }, thunkAPI) => {
      const params = {
         brand,
         start: getUnixTime(startDate),
         end: getUnixTime(endDate),
         timezone: new Date().getTimezoneOffset(),
      };

      try {
         const {
            turnover_by_months: {
               selected_period: currentTurnoverByMonths,
               last_symetric_period: prevTurnoverByMonths,
            },
            turnover_by_days: {
               selected_period: currentTurnoverByDays,
               last_symetric_period: prevTurnoverByDays,
            },
            turnover_by_day_period: {
               selected_period: currentTurnoverByDayPeriod,
               last_symetric_period: prevTurnoverByDayPeriod,
            },
            turnover_by_week_days: {
               selected_period: currentTurnoverByWeekPeriod,
               last_symetric_period: prevTurnoverByWeekPeriod,
            },
            turnover_by_hours: {
               selected_period: currentTurnoverByHours,
               last_symetric_period: prevTurnoverByHours,
            },
         } = await getData(EP_PERIODICITY_STATS, { params });

         const { periodStatsData } = thunkAPI.getState().periodicity;

         return {
            salesGrowth: {
               ...periodStatsData?.salesGrowth,
               transactions: generateMonthlyChartData(
                  {
                     current: currentTurnoverByMonths || {},
                     prev: prevTurnoverByMonths || {},
                  },
                  {startDate,unit: 'month'},
                  t,
               ),
               /*  transactions: generateCustomDataChartSalesGrowth(
            { current: currentTurnoverByMonths || {}, prev: prevTurnoverByMonths || {} },
            {
              startDate,
              unit: 'month',
              customId: true, // custom id for each dataset
              lineDashed: 'prev',
            },
            t,
          ), */
            },
            salesGranularity: {
               salesByDate: generateDataChart(
                  { current: currentTurnoverByDays || {}, prev: prevTurnoverByDays || {} },
                  {
                     startDate,
                     customId: true, // custom id for each dataset
                     tooltip: {
                        title: (value) => `${t('TEXTS.DAY', { ns: 'translation' })}: ${value}`,
                     },
                     lineDashed: 'prev',
                  },
                  t,
               ),
               salesByPartDay: generateDataChart(
                  {
                     current: currentTurnoverByDayPeriod || {},
                     prev: prevTurnoverByDayPeriod || {},
                  },
                  {
                     customId: true, // custom id for each dataset
                  },
                  t,
               ),
               salesByWeekDay: generateDataChart(
                  {
                     current: currentTurnoverByWeekPeriod || {},
                     prev: prevTurnoverByWeekPeriod || {},
                  },
                  {
                     startDate,
                     customId: true, // custom id for each dataset
                     lineDashed: 'prev',
                  },
                  t,
               ),
               salesByHoursDay: generateDataChart(
                  { current: currentTurnoverByHours || {}, prev: prevTurnoverByHours || {} },
                  {
                     startDate,
                     unit: 'hour',
                     customId: true, // custom id for each dataset
                     lineDashed: 'prev',
                  },
                  t,
               ),
            },
         };
      } catch (error) {
         return thunkAPI.rejectWithValue(error?.message);
      }
   },
);

export const getPeriodicityPerformances = createAsyncThunk(
   'periodicity/getPeriodicityPerforms',
   async ({ startDate, endDate, brand = 0 }, thunkAPI) => {
      const growthMsg = 'growth';
      const decreaseMsg = 'decrease';
      try {
         const params = {
            brand,
            start: getUnixTime(startDate),
            end: getUnixTime(endDate),
            timezone: new Date().getTimezoneOffset(),
         };
         const result = await getData(EP_PERIODICITY_PERFORMANCES, { params });

         const {
            last_symetric_period_sales: lastSymmetricPeriodSales,
            selected_period_sales: selectedPeriodSales,
         } = result;

         const tmpSales = calcPercentageGrowth(lastSymmetricPeriodSales, selectedPeriodSales);

         return {
            salesGrowth: {
               ...thunkAPI.getState().periodicity?.periodStatsData?.salesGrowth,
               sales: {
                  value: tmpSales > 0 ? tmpSales : tmpSales * -1,
                  label: tmpSales >= 0 ? growthMsg : decreaseMsg,
               },
            },
         };
      } catch (error) {
         return thunkAPI.rejectWithValue(error?.message);
      }
   },
);
