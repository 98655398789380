import { createAsyncThunk } from '@reduxjs/toolkit'

export const getCampaignsStats = createAsyncThunk(
  'campaigns/getCampaignsStats',
  async ({ startDate, endDate, brand = 0 }, thunkAPI) => {
    try {
      return true
    } catch (error) {
      return thunkAPI.rejectWithValue('MSG_ERROR_WRONG')
    }
  },
)
