import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectUser } from '../../../redux/user/user.slice.js'
import { login, logout, setCurrentUser } from '../../../redux/user/user.thunk.js'
import { getHomeRouteForLoggedInUser } from '../../../utility/Utils.js'
import { auth, database } from './firebase.js'

export const useAuth = () => {
  const { user, isLoadingUser } = useSelector(selectUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { hash, search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const persistTokenType = searchParams.get('persistTokenType')

  const onChange = async (userData) => {
    const additionalData = await database
      .ref(`users/${userData?.uid}`)
      .once('value')
      .then((snap) => (snap.exists() ? snap.val() : null))

    if (userData) {
      dispatch(
        setCurrentUser({
          userData,
          additionalData,
        }),
      )
    }
  }

  const loginUser = async (data) => {
    return new Promise((resolve, reject) => {
      dispatch(login(data))
        .then((res) => {
          resolve(navigate(getHomeRouteForLoggedInUser('brand')))
        })
        .catch((err) => {
          reject(false)
        })
    })
  }

  const autoLogin = () => {
    if (hash && hash.length > 50) {
      loginUser({ token: hash, persistTokenType })
    }
  }

  const currentUser = () => {
    auth.onAuthStateChanged((userData) => onChange(userData))
  }

  const logoutUser = () => {
    dispatch(logout()).then(() => {
      navigate('/login')
    })
  }

  const getClaimsRole = async (userData) => {
    const idTokenResult = await userData.getIdTokenResult()
    const claims = idTokenResult.claims
    const role = claims?.admin ? 'admin' : claims?.brand ? 'brand' : null

    return role
  }

  const getIdToken = async (user) => {
    const idTokenRes = await user.getIdTokenResult()
    return idTokenRes.token
  }

  return {
    user,
    logoutUser,
    loginUser,
    isLoadingUser,
    getIdToken,
    autoLogin,
    currentUser,
  }
}
