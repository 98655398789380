import { createSlice } from '@reduxjs/toolkit';
import { getDashboardGlobal, getDashboardStats } from './dashboard.thunk.js';

const initialState = {
   dashStatsData: {
      global: {
         turnover: undefined,
         transactions: undefined,
         average_cart: undefined,
         activeInfs: undefined,
         clicks: undefined,
         rateConversionData: undefined,
      },
      bestOf: {
         device: '',
         color: '',
         product: '',
         day: '',
         categ: '',
         dayPart: '',
         influencer: {},
         socialMedia: '',
      },
      charts: {
         salesPartDay: {
            labels: [],
            datasets: {
               label: '',
               data: [],
            },
         },
         os: {
            labels: [],
            datasets: {
               label: '',
               data: [],
            },
         },
         categ: {
            labels: [],
            datasets: {
               data: [],
            },
         },
         influencers: {
            labels: [],
            datasets: {
               data: [],
            },
         },
         products: {
            labels: [],
            datasets: {
               data: [],
            },
         },
         countries: {
            labels: [],
            datasets: {
               data: [],
            },
         },
         campaignSales: {
            labels: [],
            datasets: {
               data: [],
            },
         },
      },
   },
   getDashboardGlobalStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   getDashboardStatsStatus: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed',
   errors: {},
};

export const dashboardSlice = createSlice({
   name: 'dashboard',
   initialState,
   extraReducers(builder) {
      builder
         .addCase(getDashboardStats.pending, (state, action) => {
            state.getDashboardStatsStatus = 'loading';
            state.dashStatsData = initialState.dashStatsData;
         })
         .addCase(getDashboardStats.fulfilled, (state, action) => {
            delete state.errors.dashStats; //find error in Errors and delete it
            state.dashStatsData = { ...state.dashStatsData, ...action.payload };
            state.getDashboardStatsStatus = 'succeeded';
         })
         .addCase(getDashboardStats.rejected, (state, action) => {
            // state.errors = {...state.errors, dashStatsData: action.error.message};
            state.errors = { ...state.errors, dashStats: action.payload };
            state.getDashboardStatsStatus = 'failed';
         })

         .addCase(getDashboardGlobal.pending, (state, action) => {
            state.getDashboardGlobalStatus = 'loading';
         })
         .addCase(getDashboardGlobal.fulfilled, (state, action) => {
            delete state.errors.dashGlobal; //find error in Errors and delete it
            state.dashStatsData = { ...state.dashStatsData, ...action.payload };
            state.getDashboardGlobalStatus = 'succeeded';
         })
         .addCase(getDashboardGlobal.rejected, (state, action) => {
            state.errors = { ...state.errors, dashGlobal: action.payload };
            state.getDashboardGlobalStatus = 'failed';
         });
   },
});

export const selectDashboardData = (state) => state.dashboard.dashStatsData;

export const selectDashboardGlobalStatus = (state) => state.dashboard.getDashboardGlobalStatus;

export const selectDashboardStatsStatus = (state) => state.dashboard.getDashboardStatsStatus;

export const selectDashboardErrorsStats = (state) => state.dashboard.errors?.dashStats;

export const selectDashboardErrorsGlobal = (state) => state.dashboard.errors?.dashGlobal;

export default dashboardSlice.reducer;
