import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

const BasicCard = ({ action, title, subtitle, body, footer, style, bodyStyle, spaced = false }) => {
   return (
      <Card style={style} className="h-100 mb-0">
         {action || title ? (
            <CardHeader style={{ paddingBottom: !spaced ? '10px' : 0 }}>
               <div className={'d-flex flex-row justify-content-between w-100 h-100'}>
                  {title && (
                     <div style={{ minHeight: !spaced ? '46px' : 0 }}>
                        {title && <h5 className="text-black">{title}</h5>}
                        {subtitle && <p className="m-0">{subtitle}</p>}
                     </div>
                  )}
                  {action && (
                     <small onClick={action.onClick}>
                        <a className="actionLink">{action.label}</a>
                     </small>
                  )}
               </div>
            </CardHeader>
         ) : (
            <></>
         )}
         {body ? <CardBody style={bodyStyle}>{body}</CardBody> : <> </>}
         {footer ? <CardFooter>{footer}</CardFooter> : <> </>}
      </Card>
   );
};

export default BasicCard;
