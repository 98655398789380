import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'

// Relative Dependencies
import { firebaseConfig } from '@src/configs/firebaseConfig.js'

export default firebase.initializeApp(firebaseConfig)
/* if (process.env.REACT_APP_STAGE === "dev")
 firebase.auth().useEmulator("http://localhost:9099/", { disableWarnings: true }); */
export const database = firebase.database()

export const auth = firebase.auth()
