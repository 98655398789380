import botImg from '@assets/icons/bot.png'
import { ReactComponent as Logo } from '@assets/icons/logo-white.svg'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectDisplayCaptcha, selectUser } from '../redux/user/user.slice.js'
import { UpdateCaptcha, setCaptcha } from '../redux/user/user.thunk.js'
import BlankCard from './shared/Card.js'
import { database } from '../@core/auth/firebase/firebase.js'

function ReCaptcha(props) {
  const { t } = useTranslation('translation')
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const displayCaptcha = useSelector(selectDisplayCaptcha)

  const onChange = () => {
    dispatch(UpdateCaptcha({ displayCaptcha: false, uid: user.user.uid }))
  }

  useEffect(() => {
    let user_uid = user.user.uid
    if (user_uid) {
      const databaseRef = database.ref(`users/${user_uid}/displayCaptcha`)
      const onDataChange = (snapshot) => {
        const Captcha = snapshot.val()
        dispatch(setCaptcha(Captcha === true ? true : false))
      }

      databaseRef.on('value', onDataChange)

      return () => {
        databaseRef.off('value', onDataChange)
      }
    } else {
      dispatch(setCaptcha(false))
    }
  }, [])

  if (displayCaptcha === null) {
    return null
  }
  if (!displayCaptcha) {
    return props.children
  }

  return (
    <div className=' bg-secondary overflow-hidden  py-3' style={{ minHeight: '100vh' }}>
      <div className='container-sm text-center'>
        <div className='row justify-content-center align-items-center h-100'>
          <div className='col-sm-12 col-md-5'>
            <div className='row mb-2'>
              <div className='col'>
                <Logo width='100%' style={{ maxWidth: '421px' }} />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <BlankCard
                  content={
                    <div className='row p-2'>
                      <div className='col'>
                        <div className='row'>
                          <div className='col'>
                            <img src={botImg} alt='bot' width={195} height={238} />
                          </div>
                        </div>
                        <div className='row mb-2'>
                          <div className='col'>
                            <div className='row'>
                              <div className='col'>
                                <h2>{t('RECAPTCHA.TITLE')}</h2>
                              </div>
                            </div>
                            <div className='row mb-1'>
                              <div className='col'>
                                <h3>{t('RECAPTCHA.SUBTITLE')}</h3>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col'>
                                <p>{t('RECAPTCHA.DESCRIPTION')}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row justify-content-center' style={{ height: '78px' }}>
                          <div className='w-auto'>
                            <ReCAPTCHA
                              sitekey='6LfKNBknAAAAAMNGTwjW-vuamt7afoHtPZQGMH2i'
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReCaptcha
