import { Card, CardBody, CardImg } from 'reactstrap';
import PlaceHolder from '../../../@core/components/placeHolder';
import './image.scss';

const ImageCard = ({ src, alt, content }) => {
   return (
      <Card className="card-developer-meetup h-100">
         {src ? (
            <div style={{ overflow: 'hidden', maxHeight: '160px' }}>
               <CardImg
                  top
                  width="100%"
                  src={src}
                  alt={alt}
                  style={{ maxHeight: 'inherit', objectFit: 'cover' }}
               />
            </div>
         ) : (
            <PlaceHolder variant="verticalBar" width="150px" />
         )}
         <CardBody>{content}</CardBody>
      </Card>
   );
};

export default ImageCard;
