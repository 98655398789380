import { formatCustomDate, sortObjectByKeysNumber } from '../../utility/Utils';

/**
 *
 * @param {[{[key]: value}]} data array of object
 * @param {startDate, unit, type} options Object
 * @returns
 */
export const generateDataChart = (data, options, t) => {
   const tmpArrayLabels = Object.values(data)
      .map((d) => Object.keys(d))
      .reduce((acc, val) => {
         return acc.concat(val);
      }, []);

   let labels = [...new Set(tmpArrayLabels)].sort((a, b) => a - b);

   // Datasets
   let datasets = Object.entries(data).map(([key, value]) => {
      return {
         ...options,
         ...(options?.customId && { customId: key }),
         label: t ? t(`TEXTS.${key.toUpperCase()}_PERIOD`, { ns: 'translation' }) : key,
         data: labels.map((l) => {
            return value[l] || 0;
         }),
         ...(options?.lineDashed === key && { borderDash: [10, 5] }),
      };
   });

   if (options.unit) {
      labels = labels.map((l) => {
         const label = l.includes('-')
            ? new Date(l)
            : formatCustomDate(options.startDate, l, options.unit);
         return label;
      });
   }

   return {
      ...(options?.unit && { unit: options.unit }),
      labels,
      datasets,
   };
};
export function generateMonthlyChartData(networkResponse,options, t) {
   const { current, prev } = networkResponse;

   let prevData = []

   // Extract years and months
   const allYears = [...new Set([...Object.keys(current), ...Object.keys(prev)])];

   // Sort years for consistency
   allYears.sort((a, b) => parseInt(a) - parseInt(b));

   const labels = [];
   const selectedData = [];
   const symmetricData = [];

   for (const year of allYears) {
      const allMonths = [
         ...new Set([
            ...(current[year] ? Object.keys(current[year]) : []),
          //  ...(prev[year] ? Object.keys(prev[year]) : []),
         ]),
      ];

      // Sort months for consistency
      allMonths.sort((a, b) => parseInt(a) - parseInt(b));

      for (const month of allMonths) {
         labels.push(`${year}-${month.padStart(2, '0')}-01T00:00:00.000Z`);
         selectedData.push((current[year] && current[year][month]) || '0');
         symmetricData.push((prev[year] && prev[year][month]) || '0');
      }
     
   }

   for(const year in prev){
      Object.keys(prev[year]).map((m)=>{
         prevData =[...prevData, formatCustomDate(options.startDate, m, options.unit) ]
      })
   }


   return {
      unit: 'month',
      labels,
      datasets: [
         {
            label:
               t && current ? t(`TEXTS.CURRENT_PERIOD`, { ns: 'translation' }) : 'Selected Period',
            data: selectedData,
         },
         {
            label: t && prev ? t(`TEXTS.PREV_PERIOD`, { ns: 'translation' }) : 'Symmetric Period',
            data: symmetricData,
            borderDash: [10, 5],
         },
      ],
      tooltip:{
         prevData:{
            labels: prevData
         }
      }
   };
}
export const generateCustomDataChartSalesGrowth = (data, options, t) => {
   const currentData = createObjNestedObj(data.current);
   const prevData = createObjNestedObj(data.prev);

   const prevSorted = sortObjectByKeysNumber(prevData);
   const currentSorted = sortObjectByKeysNumber(currentData);

   const prevValues = Object.values(prevSorted);

   const newCustomPrevData = Object.keys(currentSorted).reduce((acc, currentKey, index) => {
      return {
         ...acc,
         ...(prevValues[index] ? { [currentKey]: prevValues[index] } : { [currentKey]: 0 }),
      };
   }, {});

   return {
      ...generateDataChart(
         {
            current: currentSorted,
            prev: newCustomPrevData,
         },
         options,
         t,
      ),
      tooltip: {
         prevData: generateDataChart(
            {
               custom: prevSorted,
            },
            options,
         ),
         t,
      },
   };
};

function createObjNestedObj(obj, parentKey = null, newObject = {}) {
   for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
         let newParentKey = parentKey !== null ? `${parentKey}-${key}`.trim() : key.trim();
         createObjNestedObj(obj[key], newParentKey, newObject);
      } else {
         const newKey = `${parentKey}-${key}`.trim();
         newObject[newKey] = obj[key];
      }
   }

   return newObject;
}

//! Old version of data chart format
/**
 * mapped Sales By Part of Day
 * @param values
 * @returns {{datasets: {data: unknown[]}, labels: string[]}}
 */
export const mappedSalesByPartDay = (values) => ({
   labels: values && Object.keys(values).map((key) => key),
   datasets: {
      data: Object.values(values).map((value) => value),
   },
});
