import { getData } from '@api/api.js';
import { EP_CONVERSIONS_PERFORMANCES } from '@api/endPoints.js';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUnixTime } from 'date-fns';
import _ from 'lodash';
import { chartArrayMapper, toPercent } from '../../utility/Utils';
import { influencerTableData, socialMediaTableData, subCategTableData } from './conversion.mapper';

export const getConversionsPerform = createAsyncThunk(
   'conversion/getConversionsPerform',
   async ({ startDate, endDate, brand = 0, language = null, t }, thunkAPI) => {
      try {
         const params = {
            brand,
            start: getUnixTime(startDate),
            end: getUnixTime(endDate),
            timezone: new Date().getTimezoneOffset(),
         };

         const {
            conversion_rate_total: conversionsRate,
            conversions_per_infs: conversionsPerInfs,
            conversions_per_supplier: conversionsPerSupplier,
            conversions_per_socialmedias: conversionsPerSocialMedias,
         } = await getData(EP_CONVERSIONS_PERFORMANCES, { params });

         const { conversionsStatsData } = thunkAPI.getState().conversion;

         const conversionsPerSupplierOrderBy = conversionsPerSupplier
            ? _.orderBy(conversionsPerSupplier, [(item) => parseInt(item.sales_nbr, 10)], ['desc'])
            : [];
         const conversionsPerInfsOrderBy = conversionsPerInfs
            ? _.orderBy(conversionsPerInfs, [(item) => parseInt(item.sales_nbr, 10)], ['desc'])
            : [];
         const conversionsPerSocialMediasOrderBy = conversionsPerSocialMedias
            ? _.orderBy(
                 conversionsPerSocialMedias,
                 [(item) => parseInt(item.sales_nbr, 10)],
                 ['desc'],
              )
            : [];

         return {
            ...conversionsStatsData,
            conversionsRate: Number(conversionsRate) ? toPercent(Number(conversionsRate) * 100) : 0,
            subCateg: {
               chartData: chartArrayMapper(
                  conversionsPerSupplierOrderBy.slice(0, 6),
                  `supplier`,
                  'sales_nbr',
                  null,
                  null,
                  null,
                  null,
                  t,
               ),
               tableData: subCategTableData(conversionsPerSupplier, language, t),
            },
            influencer: {
               chartData: chartArrayMapper(
                  conversionsPerInfsOrderBy.slice(0, 6),
                  'influencer',
                  'sales_nbr',
               ),
               tableData: influencerTableData(conversionsPerInfs, t),
            },
            socialMedia: {
               chartData: chartArrayMapper(
                  conversionsPerSocialMediasOrderBy.filter((s) => s.socialMedia).slice(0, 6),
                  'socialMedia',
                  'sales_nbr',
                  null,
                  null,
                  null,
                  null,
                  t,
               ),
               tableData: socialMediaTableData(conversionsPerSocialMedias, t),
            },
         };
      } catch (error) {
         return thunkAPI.rejectWithValue(error?.message);
      }
   },
);
