import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import themeConfig from '../../../../../configs/themeConfig';
import { getHomeRouteForLoggedInUser } from '../../../../../utility/Utils';
import { useAuth } from '../../../../auth/firebase/useAuth';

const VerticalMenuHeader = (props) => {
   const { user: userData } = useAuth();

   // ** Props
   const { setMenuVisibility, setGroupOpen, menuHover } = props;

   // ** Vars
   const user = userData;

   // ** Reset open group
   useEffect(() => {
      if (!menuHover) setGroupOpen([]);
   }, [menuHover]);

   // ** Menu toggler component
   const Toggler = () => {
      if (!menuHover) {
         return (
            <Disc
               size={20}
               data-tour="toggle-icon"
               className="text-primary toggle-icon d-none d-xl-block"
               onClick={() => setMenuCollapsed(true)}
            />
         );
      } else {
         return (
            <Circle
               size={20}
               data-tour="toggle-icon"
               className="text-primary toggle-icon d-none d-xl-block"
               onClick={() => setMenuCollapsed(false)}
            />
         );
      }
   };

   const { appLogoImage, collapsedLogoImage } = themeConfig.app;
   return (
      <NavLink className="navbar-brand" to={user ? getHomeRouteForLoggedInUser('brand') : '/'}>
         <div className="logo-container">
            <img
               src={appLogoImage}
               className="expanded-logo"
               alt="expanded logo"
               style={{ opacity: menuHover ? 1 : 0 }}
            />
            <img
               src={collapsedLogoImage}
               className="collapsed-logo"
               alt="collapsed logo"
               style={{ opacity: menuHover ? 0 : 1 }}
            />
         </div>
      </NavLink>
   );
};

export default VerticalMenuHeader;
