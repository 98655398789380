import { enUS, fr } from 'date-fns/locale'
import { DefaultRoute } from '../router/routes'

import { format, getUnixTime } from 'date-fns'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' },
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateFns = (date, formatter, language) => {
  const newDate = new Date(date)
  return format(newDate, formatter, {
    locale: language === 'en' ? enUS : fr,
  })
}
// ** Return timestamp

export const formatDateToTimestamp = (value) => {}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */

export const getAuthStorage = () => {
  // if (typeof window !== 'undefined') {
  //   const sessionStorage = sessionStorage.getItem("user")

  //   const localStorage = localStorage.getItem("user")

  // }

  return null
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 * @param location
 */
export const getHomeRouteForLoggedInUser = (userRole, location = null) => {
  if (userRole === 'admin' || userRole === 'brand') return !!location ? location : DefaultRoute
  // if (userRole === 'brand') return '/access-control'
  return '/login'
}

// ** format Currency
export const compactNumber = (value) => {
  const formatter = new Intl.NumberFormat('fr-EU', {
    notation: 'compact',
    minimumFractionDigits: 2,
  })
  const fraction = new Intl.NumberFormat('de-DE', {
    notation: 'compact',
    minimumFractionDigits: 0,
  })

  return value % 1 === 0
    ? fraction.format(value).replace(/\./g, ' ')
    : formatter.format(value).replace(/\./g, ' ')

  /*return new Intl.NumberFormat("fr-eu", {
        notation: "compact",
    }).format(value);*/
}

export const formatCurrencyEUR = (value) => {
  return new Intl.NumberFormat('fr-eu', { style: 'currency', currency: 'EUR' }).format(value)
}

export const formatCurrencyCompactEUR = (value) => {
  if (isNaN(value)) return '-' // Handle NaNs

  const fractionDigits = value % 1 === 0 ? 0 : 2
  return new Intl.NumberFormat().format(parseFloat(value).toFixed(fractionDigits)) + ' €'
}

export const calcPercentageGrowth = (prevPeriod, currentPeriod) => {
  const prevValue = Number(prevPeriod)
  const currentValue = Number(currentPeriod)

  // If both values are 0, growth is 0%
  if (prevValue === 0 && currentValue === 0) {
    return 0
  }

  // If previous value is 0 and current value is not
  if (prevValue === 0) {
    if (currentValue > 0) {
      return 100
    } else {
      return 0 // Represents infinite decline
    }
  }
  const growth = currentValue - prevValue
  const growthPercentage = (growth / prevValue) * 100

  return growthPercentage.toFixed(2)
}

export const toPercent = (number, float = 2) => {
  float = typeof number === 'number' && number % 1 !== 0 ? 2 : 0

  return parseFloat(number).toFixed(float) + '%'
}
export const toExactPercent = (number) => {
  return parseFloat(number).toFixed(0) + '%'
}

// display: ["currency", "compact", "percentage"]

/* */
export const formatNumberChart = (value, display, float = false) => {
  switch (display) {
    case 'currency':
      return formatCurrencyCompactEUR(value)
    case 'percentage':
      return float ? toExactPercent(value) : toPercent(value)
    case 'number': {
      return toInteger(value)
    }
    default:
      return compactNumber(value)
  }
}

const toInteger = (value) => {
  return parseInt(value)
}

export const randomNumber = (array) => {
  return Math.floor(Math.random() * array.length)
}

export const removeToken = (session) => {
  sessionStorage?.removeItem('authUser')

  localStorage?.removeItem('authUser')
}

export const formateDisplayTime = {
  //     'millisecond': 'yyyy MMM dd',
  //    'second': 'MMM dd',
  //    'minute': 'MMM dd',
  hour: 'HH:mm',
  day: 'dd MMM',
  week: `'Week' ww`,
  month: 'MMM yyyy',
  quarter: 'MMM dd',
  year: 'yyyy',
}

// format custom date device :(
import addHours from 'date-fns/addHours'
import addYears from 'date-fns/addYears'
import getDay from 'date-fns/getDay'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'

export const formatCustomDate = (startDate, data, type, year = null) => {
  const y = year ? year : getYear(new Date(startDate))
  const m = getMonth(new Date(startDate))
  const d = new Date(startDate).getDate()

  switch (type) {
    case 'hour':
      return addHours(startDate, data)
    case 'day':
      return new Date(y, m, data)
    case 'month':
      return new Date(y, data - 1)
    case 'year':
      return new Date(String(data))
    default:
      return addYears(startDate, data)
  }
}

export const groupBy = (items, key) =>
  items.reduce((result, item) => {
    return {
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }
  }, {})

// ** Format date to Timestamp adapted to local timezone

import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'

export const getUnixTimestampUTC = (date) => {
  const tmpDate = formatInTimeZone(date, 'UTC', 'yyyy-MM-dd HH:mm:ss zzz')
  const tmpZonedTime = zonedTimeToUtc(tmpDate, 'UTC')
  const dateFormatted = getUnixTime(tmpZonedTime)
  return dateFormatted
}

export const getMonthsList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const capitalizeFirstLetter = (item) => item.charAt(0).toUpperCase() + item.slice(1)

export const getLabels = (list, startDate, unit, globalList) =>
  list.map((item, index) => {
    let filtredGlobalList = globalList ? globalList.filter((item2) => item2[unit] == item) : []
    let year = filtredGlobalList.length > 0 ? filtredGlobalList.shift()?.year : ''

    return item === 0 ? startDate : formatCustomDate(startDate, item, unit, year)
  })

export const tableCustomStyles = (e) => {
  const rowsStyle = e === 'clicked' ? { '&:hover': { cursor: 'pointer' } } : {}

  return {
    headRow: {
      style: {
        width: '100%',
        backgroundColor: '#f3f2f7',
        fontWeight: 'bold',
      },
    },
    rows: { style: rowsStyle },
  }
}

export const truncateString = (string, limit) => {
  if (string.length > limit) {
    return string.substring(0, limit) + '...'
  } else {
    return string
  }
}
export function calculateBestPosition(label, length = 15) {
  label = truncateString(label, length)
  let middle = Math.ceil(label.length / 2)
  let before = label.lastIndexOf(' ', middle)
  let after = label.indexOf(' ', middle + 1)

  if (middle - before < after - middle) {
    middle = before
  } else {
    middle = after
  }

  const str1 = label.substr(0, middle)
  const str2 = label.substr(middle + 1)
  return [str1, str2]
}

export const skeletonColors = [
  {
    backgroundColor: '#dadada',
    foregroundColor: '#fafafa',
  },
  {
    backgroundColor: '#dadada',
    foregroundColor: '#fafafa',
  },
  {
    backgroundColor: '#dadada',
    foregroundColor: '#fafafa',
  },
  {
    backgroundColor: '#ececec',
    foregroundColor: '#fafafa',
  },
  {
    backgroundColor: '#f7f7f7',
    foregroundColor: '#fafafa',
  },
]

/**
 * chart Array Mapper
 * @param {Array}values
 * @param {String}labelKey
 * @param {String}dataKey
 * @param {String}imageKey
 * @param {String}label
 * @param colorKey
 * @param tooltipKey
 * @returns {{datasets: {data: *[]}, labels: *[]}}
 */

import Placeholder from '@src/assets/images/placeholders/product_placeholder.png'
import { auth } from '../@core/auth/firebase/firebase.js'

export const chartArrayMapper = (
  values,
  labelKey,
  dataKey,
  imageKey = 'img',
  label = null,
  colorKey = '',
  tooltipKey = '',
  t,
) => {
  const labels = []
  const data = []
  const images = []
  const colors = []
  const tooltips = []
  for (const value of values || []) {
    if (t && ['other', 'Other'].includes(value[labelKey])) {
      labels.push(t('TEXTS.OTHER', { ns: 'translation' }))
    } else if (t && ['NOT_IDENTIFIED'].includes(value[labelKey])) {
      labels.push(t('COMMON.NOT_IDENTIFIED', { ns: 'conversion' }))
    } else {
      labels.push(value[labelKey] ?? '-')
    }

    if (t && ['other', 'Other'].includes(value[dataKey])) {
      data.push(t('TEXTS.OTHER', { ns: 'translation' }))
    } else {
      data.push(value[dataKey] ?? '-')
    }

    imageKey && value[imageKey] ? images.push(value[imageKey]) : images.push(Placeholder)
    colorKey && value[colorKey] && colors.push(value[colorKey])
    tooltipKey &&
      value[tooltipKey] &&
      tooltips.push(formatNumberChart(value[tooltipKey], 'currency'))
  }

  const datasets = { data }
  images.length > 0 ? (datasets.images = images) : ''
  colors.length > 0 ? (datasets.colors = colors) : ''
  tooltips.length > 0 ? (datasets.tooltips = tooltips) : ''
  label || label === '' ? (datasets.label = label) : ''

  return { labels, datasets }
}

/**
 * get size From Ratio
 * @param {Number}naturalWidth
 * @param {Number}naturalHeight
 * @returns {{width: (number|number), height: (number|number)}}
 */
export const sizeFromRatio = (naturalWidth, naturalHeight) => {
  const aspectRatio = naturalWidth / naturalHeight
  let width = 80 * aspectRatio
  let height = 80 / aspectRatio
  width = width > 80 ? 80 : width < 40 ? 40 : width
  height = height > 80 ? 80 : height < 65 ? 65 : height

  return { width, height }
}

export const roundedImage = (ctx, x, y, width, height, radius) => {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  ctx.lineTo(x + width, y + height - radius)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  ctx.lineTo(x + radius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()
}

export const getUserToken = async () => {
  return new Promise((resolve, reject) => {
    const unsub = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await auth.currentUser.getIdToken()
        resolve(token)
      } else {
        resolve(null)
      }
      unsub()
    })
  })
}

export const sortObjectByKeysNumber = (object, direction) => {
  return Object.keys(object)
    .sort((a, b) => {
      if (direction === 'desc') {
        return Number(b) - Number(a)
      }
      return Number(a) - Number(b)
    })
    .reduce((acc, key) => {
      return { ...acc, [` ${key}`]: object[key] }
    }, {})
}

export const getFormattedDate = (date, unit, language) => {
  if (['hour', 'minute'].includes(unit)) {
    return new Date(date)?.toLocaleTimeString(language)
  }
  return new Date(date)?.toLocaleString(language, {
    ...(['month', 'weekday'].includes(unit) && {
      [unit]: 'long',
    }),
    ...(['day', 'year'].includes(unit) && {
      [unit]: 'numeric',
    }),
  })
}
export function timestampToDate(timestamp) {
  // Convert the UNIX timestamp from seconds to milliseconds
  const date = new Date(timestamp * 1000)

  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed in JS
  const year = date.getFullYear()

  // Return the formatted date
  return `${day}/${month}/${year}`
}
export function customRound(num) {
  return num - Math.floor(num) >= 0.5 ? Math.ceil(num) : Math.floor(num)
}
