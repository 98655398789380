import { useState, useEffect } from 'react'
import DefaultCard from './cards/DefaultCard'
import ImageCard from './cards/ImageCard'
import BasicCard from './cards/BasicCard'

const BlankCard = ({ type, content, header, footer }) => {
  const [cardByType, setCardByType] = useState('default')

  useEffect(() => {
    switch (type) {
      case 'default':
        setCardByType(<DefaultCard content={content} />)
        break
      case 'image':
        setCardByType(<ImageCard content={content} />)
        break
      case 'basic':
        setCardByType(<BasicCard />)
      default:
        setCardByType(<DefaultCard content={content} header={header} footer={footer} />)
    }
  }, [type])

  return <>{cardByType}</>
}

export default BlankCard
