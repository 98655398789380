import { createSlice } from '@reduxjs/toolkit'
import { defineds } from '../../@core/components/datePickerAction/utils/utils.js'

const initialState = {
  // ** Stats ** STATUS
  datePicker: [
    {
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfToday,
      key: 'selection',
    },
  ],
}

export const datePickerSlice = createSlice({
  name: 'datePicker',
  initialState,
  reducers: {
    setDatePicker: (state, action) => {
      state.datePicker = action.payload
    },
    clearDatePicker: (state, action) => {
      state.datePicker = initialState.datePicker
    },
  },
})

export const { setDatePicker, clearDatePicker } = datePickerSlice.actions

export const selectDatePicker = (state) => state.datePicker

export default datePickerSlice.reducer
